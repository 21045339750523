import { IUseScroll } from '@hooks/scroll/scroll';
import React from 'react';

const defaultValues: IUseScroll = {
  fixElement: false,
  isKeyboardOpen: false,
  positionBottom: false,
  unpinElement: false,
};

export const UseScrollContext = React.createContext<IUseScroll>(defaultValues);
