import React from 'react';

const WishListIcon: React.FunctionComponent<{ className?: string; onClick?: () => void; }> = ({
  className,
  onClick,
}) => {
  return (
    <svg className={className} onClick={onClick} width="21" height="20" viewBox="0 0 21 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1603 2.61079C18.0986 1.5488 16.691 0.903117 15.1935 0.791172C13.696 0.679226 12.2081 1.10845 11.0003 2.00079C9.72793 1.05443 8.14427 0.625302 6.5682 0.799828C4.99212 0.974354 3.54072 1.73957 2.50625 2.94138C1.47178 4.14318 0.931098 5.69231 0.993077 7.2768C1.05506 8.8613 1.71509 10.3635 2.84028 11.4808L10.2903 18.9308C10.3832 19.0245 10.4938 19.0989 10.6157 19.1497C10.7376 19.2004 10.8683 19.2266 11.0003 19.2266C11.1323 19.2266 11.263 19.2004 11.3849 19.1497C11.5067 19.0989 11.6173 19.0245 11.7103 18.9308L19.1603 11.4808C19.7429 10.8985 20.2051 10.2071 20.5205 9.44616C20.8358 8.68518 20.9982 7.86952 20.9982 7.04579C20.9982 6.22206 20.8358 5.4064 20.5205 4.64542C20.2051 3.88445 19.7429 3.19307 19.1603 2.61079ZM17.7503 10.0708L11.0003 16.8108L4.25028 10.0708C3.65545 9.47349 3.25025 8.71384 3.0855 7.88713C2.92076 7.06042 3.00381 6.20347 3.32423 5.42378C3.64465 4.64409 4.18817 3.97638 4.88662 3.50441C5.58507 3.03244 6.40734 2.77723 7.25028 2.77079C8.37639 2.77355 9.45537 3.22313 10.2503 4.02079C10.3432 4.11452 10.4538 4.18891 10.5757 4.23968C10.6976 4.29045 10.8283 4.31659 10.9603 4.31659C11.0923 4.31659 11.223 4.29045 11.3449 4.23968C11.4667 4.18891 11.5773 4.11452 11.6703 4.02079C12.4886 3.31167 13.5458 2.93993 14.6278 2.98081C15.7099 3.02169 16.736 3.47214 17.4985 4.24098C18.2609 5.00982 18.7029 6.03963 18.7348 7.12198C18.7667 8.20433 18.3862 9.25838 17.6703 10.0708H17.7503Z"
        fill="black" />
    </svg>
  );
};

export default WishListIcon;
