import { mobileMode } from '@constants/config';
import { localStorageCookieConsent } from '@constants/index';
import { removeCookie } from '@utils/cookies';
import { getLocalStorageValue } from '@utils/localStorage';

export interface CookieListItem {
  isActive: boolean;
  isDisabled: boolean;
  name: string;
  type: number;
}

export const TYPE_ESSENTIAL: number = 1;
export const TYPE_MARKETING: number = 2;
export const TYPE_ANALYTICS: number = 3;
export const TYPE_TAWK: number = 4;
export const TYPE_LOGROCKET: number = 5;

export const initialCookieList: CookieListItem[] = [
  {
    isActive: true,
    isDisabled: true,
    name: 'essential',
    type: TYPE_ESSENTIAL
  },
  {
    isActive: true,
    isDisabled: false,
    name: 'marketing',
    type: TYPE_MARKETING
  },
  {
    isActive: true,
    isDisabled: false,
    name: 'analytics',
    type: TYPE_ANALYTICS
  },
  {
    isActive: true,
    isDisabled: false,
    name: 'tawk',
    type: TYPE_TAWK
  },
  {
    isActive: false,
    isDisabled: false,
    name: 'logrocket',
    type: TYPE_LOGROCKET
  }
];

export const isCookieEnabled = (type: number): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  if(mobileMode){
    return true;
  }

  const cookieConsentCookie = getLocalStorageValue(localStorageCookieConsent);
  if (cookieConsentCookie === 'true') {
    return true;
  }

  if (cookieConsentCookie) {
    return cookieConsentCookie.split(',').some((item) => item === `${type}:1`);
  }
  return false;
};

export const removeCookies = (type: number) => {
  switch (type) {
    case TYPE_MARKETING:
      removeMarketingCookies();
      break;
    case TYPE_ANALYTICS:
      removeGoogleAnalyticsCookies();
      break;

    case TYPE_TAWK:
      removeTawkCookies();
      break;
    case TYPE_LOGROCKET:
      removeLogrocketCookies();
      break;
  }
};

const removeOptions = {
  path: '/'
};

export const removeMarketingCookies = () => {
  [
    '_fbp',
    '_gcl_aw',
    '1P_JAR',
    'Conversion'
  ].map((key) => removeCookie(null, key, removeOptions));
};

export const removeGoogleAnalyticsCookies = () => {
  [
    '_ga',
    '_ga_R580RDDZVP',
    '_gcl_au'
  ].map((key) => removeCookie(null, key, removeOptions));
};

export const removeTawkCookies = () => {
  [
    'tawk_uuid_propertyId',
    'TawkConnectionTime',
    'twk_idm_key',
    '__tawkuuid'
  ].map((key) => removeCookie(null, key, removeOptions));
};

export const removeLogrocketCookies = () => {
  [
    '_lr_uf_-sknea8',
    '_lr_tabs_-sknea8%2Festore-react',
    '_lr_hb_-sknea8%2Festore-react',
    '_lr_tabs_-sknea8/estore-react',
    '_lr_hb_-sknea8/estore-react',
  ].map((key) => removeCookie(null, key, removeOptions));
};
