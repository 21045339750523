import React from 'react';

const ScanQrIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 512 512"
         xmlSpace="preserve"
         className={className}
         width="24"
         height="24">
      <g>
        <path d="M437,159c0-8.5-0.1-17,0-25.5c0.3-16.6-8-28-21.6-36.2c-5.2-3.1-11-4.5-17.1-4.4c-18.7,0.1-37.3,0.1-56,0
			c-2.6,0-5.4-0.3-7.9-1c-5.6-1.7-9.2-6.4-9.3-12.8c-0.1-6,3.3-11,9.2-13c2.2-0.7,4.6-1.1,6.8-1.1c19.2-0.1,38.3-0.3,57.5,0
			c33.6,0.5,59,23.5,65.1,54c0.8,4.2,1.2,8.6,1.2,12.9c0.1,18.8,0.1,37.6,0,56.5c0,2.1-0.3,4.3-0.9,6.4c-1.8,6.8-7.7,10-13.3,10
			c-5.9,0.1-11.2-4.2-12.8-10c-0.6-2.2-0.9-4.6-0.9-6.9C436.9,178.3,437,168.7,437,159z" />
        <path d="M143.5,449c-9.3,0-18.7,0.1-28,0c-30.9-0.6-53.1-19.6-61.9-42.5c-2.8-7.4-4.4-15-4.5-22.8c-0.2-19.6-0.1-39.3-0.1-59
			c0-8,3.8-13.1,10.6-15c6.8-1.9,14.2,1.9,16.3,8.7c0.8,2.5,1,5.2,1,7.9c0.1,18.3,0.6,36.7-0.1,54.9c-0.6,14.7,11.3,33.5,29.9,38.6
			c2.7,0.7,5.6,1,8.4,1c18.8,0.1,37.6,0,56.5,0.1c2.6,0,5.3,0.3,7.9,1c5.5,1.6,9.6,7.1,9.5,12.7c-0.1,5.7-3.2,11.3-9.6,13.2
			c-2.2,0.7-4.6,1-6.9,1C162.8,449.1,153.1,449,143.5,449z" />
        <path d="M143.1,93c-8.5,0-17,0.1-25.5,0c-16.8-0.4-28.3,8.2-36.4,22c-3,5.1-4.3,10.7-4.2,16.7c0.1,18.7,0.1,37.3,0,56
			c0,2.6-0.3,5.4-1,7.9c-1.9,5.9-6.7,9.2-12.8,9.3c-5.9,0.1-10.9-3.2-13-9.2c-0.7-2.2-1.1-4.6-1.1-6.9c-0.1-18.8-0.3-37.6,0-56.5
			c0.5-33.4,22.9-59.7,55-66.1c4.5-0.9,9.3-1.2,13.9-1.2c18-0.1,36-0.1,54,0c2.3,0,4.6,0.3,6.9,0.9c6.2,1.5,10,7,10.1,13.3
			c0.1,5.9-4.1,11.3-9.9,12.9c-2.2,0.6-4.6,0.9-6.9,0.9C162.4,93.1,152.7,93,143.1,93z" />
        <path d="M437,354.5c0-9.5-0.1-19,0-28.5c0-2.3,0.3-4.7,0.9-6.9c1.6-5.9,6.9-10.1,12.8-10c5.6,0.1,11.6,3.3,13.3,10
			c0.5,1.9,0.9,3.9,0.9,5.9c0.1,18.8,0.3,37.6,0,56.5c-0.5,30-18.2,53.4-43.8,63.2c-7.4,2.8-15.1,4.2-22.9,4.3
			c-19.2,0.2-38.3,0.1-57.5,0c-8,0-13.1-3.7-15-10.6c-1.9-6.8,1.9-14.2,8.7-16.3c2.5-0.8,5.2-1,7.9-1c18.3-0.1,36.7-0.6,55,0.1
			c15.2,0.6,33.5-11.6,38.6-29.8c0.7-2.7,1-5.6,1.1-8.4C437.1,373.5,437,364,437,354.5z" />
      </g>
      <g>
        <path d="M168.2,273.2c0-2.1,0-3.9,0-5.7c0-27.2-0.1-54.3,0-81.5c0-4.9-1.1-8.4-6.4-9.8c-1-0.3-1.6-2-3.2-4.2c3.5,0,5.5,0,7.4,0
					c34.7,0,69.3,0.5,104-0.1c47.7-0.9,83,38.5,83.7,82.2c0.2,12.3-1.4,25.1-4.7,36.9c-6.5,24.1-24,38-47.4,44.8
					c-25.6,7.4-52,6.8-78.7,7.1c0-13.1,0-25.8,0-39.1c8.8,0,17.6,0.3,26.4-0.1c8.3-0.4,16.8-0.6,24.7-2.5
					c17.2-4.2,28.1-17.2,29.7-34.8c0.7-7.5,0.5-15.4-1-22.8c-3.6-17.4-14.7-29.3-32.1-31.2c-17.7-2-35.8-0.4-54.2-0.4
					c0,19.9,0,40.4,0,61.3C200.5,273.2,184.8,273.2,168.2,273.2z" />
      </g>
    </svg>
  );
};

export default ScanQrIcon;
