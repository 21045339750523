export const zIndex = {
  cookiesConsent: 200,
  header: 300,
  headerAction: 400,
  megaMenu: 100,
  megaMenuBackDrop: 99,
  mobileMenu: 410,
  pageRestore: 999,
  productImage: 96,
  productListFilter: 98,
  searchContainerMobile: 450,
  sliderButtons: 90,
};
