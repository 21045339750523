import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import Hidden from '@components/common/Hidden';
import useScrollBlock from '@hooks/scrollBlock';
import { CloseIcon } from '@icons/Close';
import MobileHeader from '../MobileBlockHeader/MobileHeader';

import useStyles from './styles';

interface IModalProps {
  classes?: {
    closeIcon?: string;
    paper?: string;
  };
  disableScrollBehavior?: boolean;
  onClose?: () => void;
  open: boolean;
  showClose?: boolean;
  showMobileTitle?: boolean;
  title?: string | JSX.Element;
  isLarge?: boolean;
}

const ModalComponent: React.FunctionComponent<IModalProps & PropsWithChildren> = ({
  classes,
  children,
  disableScrollBehavior = false,
  isLarge = false,
  onClose,
  open,
  showClose = true,
  showMobileTitle = false,
  title,
}) => {
  const classesModal = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);

  const { blockScroll, allowScroll } = useScrollBlock();

  useEffect(() => {
    if (!disableScrollBehavior) {
      if (open) {
        blockScroll();
      }
      return () => {
        allowScroll();
      };
    }
  }, [open]);

  return (
    <ErrorBoundary>
      <div className={classNames({ [classesModal.root]: open })} ref={rootRef}>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          disableScrollLock
          open={open}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={isLarge ? classesModal.modalLarge : classesModal.modal}
          container={() => rootRef.current}
        >
          <div className={classNames(classesModal.paper, classes?.paper)}>
            {showMobileTitle && title && (
              <Hidden mdUp={true}>
                <MobileHeader backButtonAction={onClose && onClose} title={title} />
              </Hidden>
            )}
            <Hidden mdDown={showMobileTitle}>
              {showClose && (
                <div className={classNames(classesModal.closeIcon, classes?.closeIcon)} onClick={onClose && onClose}>
                  <CloseIcon />
                </div>
              )}
              {title && (
                <div className={classesModal.header}>
                  <h2 className={classesModal.title} id="server-modal-title">
                    {title}
                  </h2>
                </div>
              )}
            </Hidden>
            {children}
          </div>
        </Modal>
      </div>
    </ErrorBoundary>
  );
};
export default ModalComponent;
