import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    closeIcon: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      width: '24px',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 62px',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    modal: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        '& > div:first-child': {
          background: 'transparent !important',
        },
        padding: 0,
      },
    },
    modalLarge: {
      alignItems: 'start',
      background: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      maxHeight: '100%',
      maxWidth: '70%',
      overflow: 'auto',
      padding: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        '& > div:first-child': {
          background: 'transparent !important',
        },
        maxWidth: '100%',
        padding: 0,
      },
    },
    paper: {
      '&:focus': {
        outline: 'none',
      },
      background: '#FFFFFF',
      borderRadius: '4px',
      boxShadow: 'none',
      padding: '21px',
      [theme.breakpoints.down('md')]: {
        backdropFilter: 'blur(16px)',
        background: 'rgba(255, 255, 255, 0.88)',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '21px',
        width: '100%',
      },
    },
    root: {
      position: 'absolute',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        left: 0,
        top: 0,
        width: '100%',
      },
    },
    title: {
      color: ColorsPalette.primary,
      fontFamily: FontCollection.inter,
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '124%',
      marginBlockEnd: '10px',
      marginBlockStart: '0',
      paddingLeft: '17px',
      textAlign: 'center',
    },
  }),
);

export default useStyles;
