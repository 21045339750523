import React from 'react';

const AccountIcon: React.FunctionComponent<{ active?: boolean; className?: string; }> = ({
  active = false,
  className,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <svg className={className} width="20" height="22" viewBox="0 0 20 22" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.7085 11.7101C14.6889 10.9388 15.4045 9.88105 15.7558 8.68407C16.107 7.48709 16.0765 6.21039 15.6683 5.03159C15.2602 3.85279 14.4948 2.83052 13.4786 2.10698C12.4624 1.38344 11.246 0.994629 9.99853 0.994629C8.75108 0.994629 7.53465 1.38344 6.51846 2.10698C5.50228 2.83052 4.73688 3.85279 4.32874 5.03159C3.92061 6.21039 3.89004 7.48709 4.24129 8.68407C4.59254 9.88105 5.30814 10.9388 6.28853 11.7101C4.60861 12.3832 3.14282 13.4995 2.04742 14.94C0.95203 16.3806 0.268095 18.0914 0.0685318 19.8901C0.0540864 20.0214 0.0656484 20.1543 0.102557 20.2812C0.139466 20.408 0.200999 20.5264 0.283643 20.6294C0.450551 20.8376 0.693315 20.971 0.958532 21.0001C1.22375 21.0293 1.48969 20.9519 1.69786 20.785C1.90602 20.6181 2.03936 20.3753 2.06853 20.1101C2.28812 18.1553 3.22022 16.3499 4.68675 15.0389C6.15328 13.7279 8.05143 13.0032 10.0185 13.0032C11.9856 13.0032 13.8838 13.7279 15.3503 15.0389C16.8168 16.3499 17.7489 18.1553 17.9685 20.1101C17.9957 20.3558 18.113 20.5828 18.2976 20.7471C18.4823 20.9115 18.7213 21.0016 18.9685 21.0001H19.0785C19.3407 20.97 19.5803 20.8374 19.7451 20.6314C19.9099 20.4253 19.9866 20.1625 19.9585 19.9001C19.758 18.0963 19.0704 16.3811 17.9694 14.9383C16.8684 13.4955 15.3954 12.3796 13.7085 11.7101ZM9.99853 11.0001C9.20741 11.0001 8.43405 10.7655 7.77625 10.326C7.11845 9.88648 6.60576 9.26176 6.30301 8.53086C6.00026 7.79995 5.92105 6.99569 6.07539 6.21976C6.22973 5.44384 6.61069 4.73111 7.1701 4.1717C7.72951 3.61229 8.44225 3.23132 9.21817 3.07698C9.99409 2.92264 10.7984 3.00186 11.5293 3.30461C12.2602 3.60736 12.8849 4.12005 13.3244 4.77784C13.7639 5.43564 13.9985 6.209 13.9985 7.00012C13.9985 8.06099 13.5771 9.07841 12.827 9.82855C12.0768 10.5787 11.0594 11.0001 9.99853 11.0001Z"
          fill="black" />
      </svg>
      {active && (
        <svg style={{ transform: 'translate(11px, -4px)', position: 'absolute' }} width="14" height="14"
             viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7" r="6" fill="#378A3F" stroke="white" strokeWidth="2" />
        </svg>
      )}
    </div>
  );
};

export default AccountIcon;
