import classNames from 'classnames';
import React from 'react';

import styles from './styles';

interface INavigationBarItemProps {
  action: () => void;
  active?: boolean;
  icon: any;
  label?: string | null;
}

const NavigationBarItem: React.FunctionComponent<INavigationBarItemProps> = ({ action, active, icon, label }) => {
  const classes = styles();
  return (<div onClick={action}
               className={classNames(classes.navigationItem, {
                 [classes.navigationItemActive]: active,
                 [classes.navigationItemTranslated]: label,
               })}>
    {icon}
    {label && (<span className={classes.navigationItemLabel}>{label}</span>)}
  </div>);
};

export default NavigationBarItem;
