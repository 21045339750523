import { IPageData, ISiteSettings, ISiteSettingsColors } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { ColorsPalette } from '@themes/colors';

export interface IPrivacyPolicyModal {
  title: string;
  content: string;
  user_consent_check_text: string;
  privacy_policy_text: string;
  privacy_policy_link: string;
}

export function getSiteSettings(pageData?: IPageData): ISiteSettings | undefined {
  return pageData?.siteSettings || undefined;
}

export function getColors(pageData?: IPageData): ISiteSettingsColors | undefined {
  return getSiteSettings(pageData)?.colors || undefined;
}

export function getSidemenuColor(pageData?: IPageData): string {
  return getColors(pageData)?.sidemenu || 'none';
}

export function getAccountColor(pageData?: IPageData): string {
  return getColors(pageData)?.account || 'none';
}

export function getFrontpageColor(pageData?: IPageData): string {
  return getColors(pageData)?.frontpage || 'none';
}

export const getMenuBarBackground = (pageData?: IPageData): string => {
  return getColors(pageData)?.menuBarBackground || ColorsPalette.primary;
};

export const getMenuBarColor = (pageData?: IPageData): string => {
  return getColors(pageData)?.menuBarColor || ColorsPalette.neutral2;
};

export function isLogRocketEnabled(pageData?: IPageData): boolean {
  return getSiteSettings(pageData)?.enableLogRocket === true;
}
export function isLogRocketOverride(pageData?: IPageData): boolean {
  return getSiteSettings(pageData)?.overrideLogRocketCookies === true;
}


export const getColorMenuBarBackground = (store?: IStore): string => {
  return store?.pageData?.data && getColors(store.pageData.data)?.menuBarBackground|| ColorsPalette.primary;
};

export const getColorFooterBackground = (store?: IStore): string => {
  return store?.pageData?.data && getColors(store.pageData.data)?.footerBackground || ColorsPalette.neutral1;
};
