import dynamic from 'next/dynamic';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from '@interfaces/store';

const SideMenu = dynamic(()=>import ('@components/layout/SideMenu'));

const SideMenuWrap: React.FunctionComponent<PropsWithChildren> = ({ children }) => {

  const sideMenu = useSelector((state: IStore) => state.page.sideMenu);
  return ( <>
        {sideMenu && <SideMenu />}
    </>);
};

export default SideMenuWrap;
