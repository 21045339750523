import { ActionCreator } from 'redux';

import { RequestMethods } from '@constants/types';
import {
  apiLoyaltyAccessCardUrl,
  customerLoyaltyAttachByIdUrl,
  getCustomerEmailConfirmUrl, passwordResetKey,
  qrScannerSubmitUrl,
} from '@constants/urls';
import { TThunkDispatch } from '@interfaces/index';
import {
  ILoyaltyProgramErrorClear,
  ILoyaltyProgramErrorShow,
  ILoyaltyProgramSignUpCompleted,
  ILoyaltyProgramSuccessMessageClear,
  ILoyaltyProgramSuccessMessageShow,
} from '@interfaces/loyaltyProgram/actions';
import { ICustomerApiError, ILoyaltyDataAttributes } from '@interfaces/loyaltyProgram/reducer';
import { IStore } from '@interfaces/store';
import { isServer } from '@utils/context';
import * as actions from '../../constants/actions/loyaltyProgram';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';

export const acErrorShow: ActionCreator<ILoyaltyProgramErrorShow> = (
  errors: ICustomerApiError[],
) => ({
  payload: { errors },
  type: actions.MESSAGE_SHOW,
});

export const acErrorClear: ActionCreator<ILoyaltyProgramErrorClear> = () => ({
  type: actions.MESSAGE_CLEAR,
});

export const acSuccessMessageShow: ActionCreator<ILoyaltyProgramSuccessMessageShow> = (
  successMessages: string[],
) => ({
  payload: { successMessages },
  type: actions.MESSAGE_SHOW,
});

export const acSuccessMessageClear: ActionCreator<ILoyaltyProgramSuccessMessageClear> = () => ({
  type: actions.MESSAGE_CLEAR,
});

export const acLoyaltyCompleted: ActionCreator<ILoyaltyProgramSignUpCompleted> = () => ({
  type: actions.LOYALTY_PROGRAM_SIGN_UP_COMPLETED,
});

export const acAttachLoyaltyProgramById = (signUpId: string, attributes?: ILoyaltyDataAttributes) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const path = `${window.location.origin}/${countryCode || 'EE'}/${languageCode || 'et'}`;
  return dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            ...attributes,
            ['reset-password-url']: `${path}?type=${passwordResetKey}&token=$token`,
            s: signUpId,
          },
          type: '',
        },
      },
      endpoint: addParamsToUrl(customerLoyaltyAttachByIdUrl(), { s: signUpId }),
      method: RequestMethods.POST_METHOD,
      types: [actions.LOYALTY_PROGRAM_ATTACH_REQUEST, actions.LOYALTY_PROGRAM_ATTACH_SUCCESS, actions.LOYALTY_PROGRAM_ATTACH_FAILED],
    },
  });
};


export const acConfirmCustomerSignupEmail: (id: string) => any = (id) => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: getCustomerEmailConfirmUrl(id),
    method: RequestMethods.GET_METHOD,
    types: [actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST, actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS, actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED],
  },
});


export const acAttachLoyaltyProgramByQr = (customerId: string | null, qrString: string) => (dispatch: TThunkDispatch, getState: () => IStore) => {
  const {
    context: { host },
  } = getState();
  const origin = isServer() ? 'http://' + host : window?.location?.origin;
  return dispatch({
    [CALL_API]: {
      body: {
        customerId,
        qrString,
      },
      endpoint: qrScannerSubmitUrl(origin),
      method: RequestMethods.POST_METHOD,
      types: [actions.LOYALTY_PROGRAM_ATTACH_QR_REQUEST, actions.LOYALTY_PROGRAM_ATTACH_QR_SUCCESS, actions.LOYALTY_PROGRAM_ATTACH_QR_FAILED],
    },
  });
};

export const acCustomerAccessCard: (customerId: string) => any = (customerId) => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: addParamsToUrl(apiLoyaltyAccessCardUrl(window.origin), { customerId }),
    method: RequestMethods.GET_METHOD,
    types: [actions.LOYALTY_PROGRAM_ACCESS_CARD_REQUEST, actions.LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS, actions.LOYALTY_PROGRAM_ACCESS_CARD_FAILED],
  },
});
