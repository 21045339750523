import LinkNext, { LinkProps } from 'next/link';
import React, { PropsWithChildren, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { acPreGoToPage } from '@actions/acPageData';
import { mobileMode } from '@constants/config';
import { PageTypes } from '@constants/pageTypes';
import { TThunkDispatch } from '@interfaces/index';

interface ILinkProps extends LinkProps {
  className?: string;
  pageType?: PageTypes;
}

const Link: React.FunctionComponent<ILinkProps & PropsWithChildren> = ({
  className,
  href,
  children,
  pageType,
  shallow = false,
  onClick,
  legacyBehavior,
  ...props
}) => {

  const dispatch = useDispatch<TThunkDispatch>();
  const onChangePage = useCallback((e) => {
    e.stopPropagation();
    if ((mobileMode || shallow)) {
      if (pageType) {
        dispatch(acPreGoToPage(pageType));
      }
      if (onClick) {
        onClick(e);
      }
    }
  }, [dispatch, pageType]);

  return (
    <LinkNext
      className={className}
      legacyBehavior={legacyBehavior}
              href={href || ''}
              shallow={mobileMode || shallow}
              onClick={!legacyBehavior ? onChangePage : undefined}
              {...props}
    >
      {
        React.isValidElement(children) && pageType && legacyBehavior ?
          React.cloneElement(children as React.ReactElement<any>, { onClick: onChangePage })
          : children
      }
    </LinkNext>
  );
};

export default Link;
