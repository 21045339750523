import React from 'react';

import { useTranslation } from '@hooks/useTranslation';

const ErrorView: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  return <div>{t('error.ComponentCrash')}</div>;
};

export default ErrorView;
