import { RefObject, useCallback, useRef, useState } from 'react';

function useRefWithCallback<T extends HTMLInputElement>(): [boolean, (node: any) => void, RefObject<T>] {
  const ref = useRef<T | null>(null);
  const [toggle, setToggle] = useState(false);
  const refCallback: (node)=> void = useCallback((node) => {
    ref.current = node;
    setToggle((val) => !val);
  }, []);

  return [toggle, refCallback, ref];
}

export default useRefWithCallback;

type TUseRefCallBack = <T extends HTMLInputElement>() => [RefObject<T>, (node: any) => void];
export const useRefWithCallbackClear: TUseRefCallBack = <T>() => {
  const ref = useRef<T | null>(null);
  const refCallback: (node)=> void = useCallback((node) => {
    ref.current = node;
  }, []);

  return [ ref, refCallback ];
};
