import React from 'react';

const DenimIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="14" height="12" viewBox="0 0 14 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.468392 0.181641H8.20378C11.266 0.181641 13.6891 2.87105 13.6891 5.94657C13.6891 11.8047 9.12244 11.818 4.72885 11.818V9.12859H7.03215C9.21563 9.12859 10.3074 7.90371 10.3074 5.9732C10.3074 4.04268 9.09581 2.84442 7.23186 2.84442H4.34274V7.10488H1.02758V1.0071C1.02758 0.99379 1.02758 0.980476 1.02758 0.967162C1.02758 0.700883 0.814554 0.487861 0.548276 0.487861C0.521648 0.487861 0.49502 0.487861 0.455078 0.501175V0.181641H0.468392Z"
        fill="#EBEBEB" />
    </svg>
  );
};

export default DenimIcon;
