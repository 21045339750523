import { Hidden } from '@mui/material';
import { HiddenProps } from '@mui/material/Hidden/Hidden';
import React from 'react';
import { useSelector } from 'react-redux';
import { IResult } from 'ua-parser-js';

import { IStore } from '@interfaces/store';


const HiddenMy: React.FunctionComponent<HiddenProps> = ({ children, ...props }) => {
  const userAgent: Partial<IResult> | null = useSelector((state: IStore) => state.context.userAgent);

  const initialValue = userAgent?.device?.type && ['tablet', 'mobile'].includes(userAgent.device.type) ? 'sm' : 'lg';
  return (<Hidden initialWidth={initialValue}  {...props}>
    {children}
  </Hidden>);
};

export default HiddenMy;
