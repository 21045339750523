import { mobileMode } from '@constants/config';
import React from 'react';

const BackArrowIcon: React.FunctionComponent<{ className?: string; onClick?: () => void }> = ({
  className,
  onClick,
}) => {
  return mobileMode ? (
    <>
      <svg onClick={onClick} className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6464 20.3536L12 20.7071L12.3536 20.3536L13.7636 18.9436L14.1168 18.5903L13.7639 18.2368L9.03558 13.5L20 13.5L20.5 13.5L20.5 13L20.5 11L20.5 10.5L20 10.5L9.03558 10.5L13.7639 5.76324L14.1168 5.40968L13.7636 5.05645L12.3536 3.64645L12 3.29289L11.6464 3.64645L3.64645 11.6464L3.29289 12L3.64645 12.3536L11.6464 20.3536Z"
          fill="black" stroke="white" />
      </svg>
    </>
  ) : (
    <svg onClick={onClick} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.41 1.41L8 -6.99382e-07L6.99382e-07 8L8 16Z" fill="black" />
    </svg>
  );
};

export default BackArrowIcon;
