import classNames from 'classnames';
import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';

import ArrowsSubmitIcon from '@icons/ArrowsSubmit';
import Button from '@mui/material/Button';

import submitButtonStyles from './styles';

interface IButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => void;
  isBig?: boolean;
  startIcon?: ReactNode | null;
  endIcon?: ReactNode | null;
  type?: EButtonType;
  id?: string;
  dataAction?: string;
  buttonDataset?: Record<string, string>;
}

export enum EButtonType {
  primary = 'primary',
  secondary = 'secondary'
}

const SubmitButton: React.FunctionComponent<IButtonProps & PropsWithChildren> = ({
  children,
  className,
  disabled = false,
  onClick,
  isBig = false,
  startIcon = <div />,
  endIcon = <ArrowsSubmitIcon />,
  type = EButtonType.primary,
  id,
  dataAction,
  buttonDataset,
}) => {

  const submitButtonClasses = submitButtonStyles();

  return (
    <Button
      id={id}
      className={className}
      disabled={disabled}
      onClick={(e) => onClick && onClick(e)}
      data-action={dataAction}
      classes={{
        contained: type === EButtonType.primary ? submitButtonClasses.contained : submitButtonClasses.containedSec,
        containedPrimary: submitButtonClasses.containedPrimary,
        containedSecondary: submitButtonClasses.containedSecondary,
        disabled: submitButtonClasses.disabled,
        endIcon: submitButtonClasses.endIcon,
        root: classNames(submitButtonClasses.root, { [submitButtonClasses.rootBig]: isBig }),
        startIcon: type === EButtonType.primary ? submitButtonClasses.startIcon : submitButtonClasses.startIconSecondary,
        text: submitButtonClasses.label, // TODO check MUI
      }}
      variant="contained"
      color={type}
      startIcon={startIcon}
      endIcon={endIcon}
      {...buttonDataset}
    >
      <span
        className={submitButtonClasses.buttonFont}
        data-action={dataAction}
      >
        {children}
      </span>
    </Button>
  );
};

export default SubmitButton;
