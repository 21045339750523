import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acGoToLastMainPage, acGoToPage, acNavigationGoBackTo } from '@actions/acNavigation';
import NavigationBarItem from '@components/layout/MobileApp/NavigationBar/NavigationItem';
import NavigationBarWrap from '@components/layout/MobileApp/NavigationBar/wrap';
import { showLoyaltyCountries } from '@constants/index';
import { accountPages, PageTypes, productsPageTypes, productsPageTypesClear } from '@constants/pageTypes';
import { INavBarSettings } from '@interfaces/pageData/reducer';

import AccountIcon from '@icons/Account';
import Bag24Icon from '@icons/Bag24';
import HomeIcon from '@icons/Home';
import ScanQrIcon from '@icons/ScanQr';
import SearchListIcon from '@icons/SearchList';
import WishListIcon from '@icons/WishList';
import { IHistoryState } from '@interfaces/history';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import Badge from '@mui/material/Badge';
import { sessionStorageMenuPage } from '@utils/sessionStorage';

import styles from './styles';

const NavigationBar: React.FunctionComponent<{}> = () => {
  const classes = styles();
  const dispatch = useDispatch<TThunkDispatch>();
  const onGoToPage = useCallback((goToPageType) => dispatch(acGoToPage(goToPageType)), [dispatch]);
  const onGoToLastMainPage = useCallback(() => dispatch(acGoToLastMainPage()), [dispatch]);
  const pageType = useSelector<IStore, PageTypes | null>((state) => state.pageData.type);
  const typeNext = useSelector<IStore, PageTypes | null>((state) => state.pageData.typeNext);
  const cartCount = useSelector<IStore, number>((state) => state.cart.count);
  const wishListCount = useSelector<IStore, number>((state) => state.wishList.count);
  const lastMainPage = useSelector<IStore, IHistoryState | null>((state) => state.pageData.lastMainPage);
  const countryCode = useSelector<IStore, string | null>((state: IStore) => state.pageData.countryCode);
  const navBarSettings = useSelector<IStore, INavBarSettings | null>((state) => state.pageData.navBar);

  const homePageTypes: PageTypes[] = [PageTypes.home];
  const accountPageTypes: PageTypes[] = [...accountPages];
  const loyaltyPageTypes: PageTypes[] = [PageTypes.account_loyalty];
  const wishListPageTypes: PageTypes[] = [PageTypes.wishlist];
  const cartPageTypes: PageTypes[] = [PageTypes.cart];

  const isAuthorized = useSelector<IStore, boolean>((state) => state.auth.isAuthorized);

  const isMenuPage = pageType === PageTypes.menu;

  const onGoToPageWithCheck = (pageTypeToGo: PageTypes) => {
    if (typeNext !== pageTypeToGo) {
      onGoToPage(pageTypeToGo);
    }
  };

  const onGoToMenuPage = () => {
    if (typeNext !== PageTypes.menu) {
      const historyStatePage = sessionStorage.getItem(sessionStorageMenuPage);
      if (historyStatePage) {
        const actionResult: boolean = acNavigationGoBackTo(Number(historyStatePage));
        if (!actionResult) {
          onGoToPageWithCheck(PageTypes.menu);
        }
      } else {
        onGoToPageWithCheck(PageTypes.menu);
      }
    }
  };

  const onMenuButtonClick = () => {
    if (!lastMainPage || (pageType && productsPageTypesClear.includes(pageType))) {
      onGoToMenuPage();
    } else {
      onGoToLastMainPage();
    }
  };
  const showLoyalty = countryCode && showLoyaltyCountries.includes(countryCode);

  const getLabel = (key: string) => {
    return navBarSettings && navBarSettings.showTranslations ? navBarSettings[key] || '' : null;
  };

  return (<NavigationBarWrap>
    <div className={classes.navigationContainer}>
      <NavigationBarItem active={homePageTypes.includes(pageType as PageTypes) && !isMenuPage}
                         action={() => onGoToPageWithCheck(PageTypes.home)}
                         icon={<HomeIcon />}
                         label={getLabel('translationHome')}
      />
      {showLoyalty && (
        <NavigationBarItem active={loyaltyPageTypes.includes(pageType as PageTypes)}
                           action={() => onGoToPageWithCheck(PageTypes.account_loyalty)}
                           icon={<ScanQrIcon />}
                           label={getLabel('translationLoyalty')}
        />)}
      <NavigationBarItem active={productsPageTypes.includes(pageType as PageTypes) || isMenuPage}
                         action={onMenuButtonClick}
                         icon={<SearchListIcon />}
                         label={getLabel('translationMenu')}
      />
      <NavigationBarItem active={wishListPageTypes.includes(pageType as PageTypes) && !isMenuPage}
                         action={() => onGoToPageWithCheck(PageTypes.wishlist)}
                         icon={wishListCount > 0 ? <Badge classes={{
                           anchorOriginTopRightRectangular: classes.anchorOriginTopRightRectangle,
                           badge: classes.badge,
                         }} badgeContent={wishListCount}>
                           <WishListIcon />
                         </Badge> : <WishListIcon />
                         }
                         label={getLabel('translationWishlist')} />
      <NavigationBarItem active={accountPageTypes.includes(pageType as PageTypes) && !isMenuPage}
                         action={() => onGoToPageWithCheck(PageTypes.account)}
                         icon={<AccountIcon active={isAuthorized} />}
                         label={getLabel('translationAccount')} />
      <NavigationBarItem active={cartPageTypes.includes(pageType as PageTypes) && !isMenuPage}
                         action={() => onGoToPageWithCheck(PageTypes.cart)}
                         icon={cartCount > 0 ? (
                           <Badge classes={{
                             anchorOriginTopRightRectangular: classes.anchorOriginTopRightRectangleCart,
                             badge: classes.badge,
                           }} badgeContent={cartCount}>
                             <Bag24Icon />
                           </Badge>
                         ) : (
                           <Bag24Icon />
                         )}
                         label={getLabel('translationCart')} />
    </div>
  </NavigationBarWrap>);
};

export default NavigationBar;
