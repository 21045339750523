import { createStyles, makeStyles } from '@mui/styles';

import { appNavigationHeightSm } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { zIndex } from '@themes/zIndex';

export default makeStyles((theme) =>
  createStyles({
    anchorOriginTopRightRectangle: {
      top: 4,
      transform: 'scale(0.7) translate(50%, 30%)',
    },
    anchorOriginTopRightRectangleCart: {
      top: 4,
      transform: 'scale(0.7) translate(50%, 47%)',
    },
    badge: {
      backgroundColor: ColorsPalette.coralPink,
      color: ColorsPalette.neutral2,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.24,
    },
    navigation: {
      // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
      '@supports (padding-top: constant(safe-area-inset-bottom))': {
        paddingBottom: 'calc(constant(safe-area-inset-bottom) * 2)',
      },
// Browsers which fully support CSS Environment variables (iOS 11.2+).
      '@supports (padding-top: env(safe-area-inset-bottom))': {
        paddingBottom: 'calc(env(safe-area-inset-bottom) * 2)',
      },

      alignItems: 'flex-start',
      backgroundColor: ColorsPalette.neutral2,
      borderTop: '1px solid ' + ColorsPalette.neutral1,
      bottom: -1,
      display: 'flex',
      flexDirection: 'row',
      position: 'fixed',
      width: '100%',
      zIndex: zIndex.header,
    },
    navigationContainer: {
      alignItems: 'center',
      backgroundColor: ColorsPalette.neutral2,
      display: 'flex',
      flexDirection: 'row',
      height: appNavigationHeightSm,
      justifyContent: 'space-around',
      width: '100%',
    },
    navigationFix: {
      bottom: -1,
    },
    navigationHide: {
      '@supports (not (padding-bottom: env(safe-area-inset-bottom)) and not (padding-bottom: constant(safe-area-inset-bottom)))': {
        bottom: -appNavigationHeightSm,
      },
      '@supports (padding-bottom: constant(safe-area-inset-bottom))': {
        bottom: `calc((constant(safe-area-inset-bottom) * 2 + ${appNavigationHeightSm}px) * -1)`,
      },
      '@supports (padding-bottom: env(safe-area-inset-bottom))': {
        bottom: `calc((env(safe-area-inset-bottom) * 2 + ${appNavigationHeightSm}px) * -1)`,
      },
    },
    navigationItem: {
      '& #searchListIcon': {
        '& path:first-child, path:nth-child(2)': {
          fill: ColorsPalette.tertiary2,
        },
      },
      '& svg:first-child:not(#searchListIcon)': {
        '& path': {
          fill: ColorsPalette.tertiary2,
        },
      },
      alignItems: 'center',
      color: ColorsPalette.tertiary2,
      display: 'flex',
      flex: '1 0 0',
      flexDirection: 'column',
      fontSize: 10,
      height: '100%',
      justifyContent: 'center',
      //  width: '20%',
    },
    navigationItemActive: {
      '& #searchListIcon': {
        '& path:first-child, path:nth-child(2)': {
          fill: ColorsPalette.primary,
        },
      },
      '& $navigationItemLabel': {
        color: ColorsPalette.primary,
      },
      '& svg:first-child:not(#searchListIcon)': {
        '& path': {
          fill: ColorsPalette.primary,
        },
      },
    },
    navigationItemLabel: {
      alignItems: 'center',
      height: 15,
    },
    navigationItemTranslated: {
      justifyContent: 'space-between',
      paddingTop: '8px',
    },
  }));
