import classNames from 'classnames';
import React from 'react';

import BackArrowIcon from '@icons/BackArrow';
import styles from './styles';

interface IMobileHeaderBlockProps {
  backButtonAction?: () => void;
  title: string | JSX.Element;

}

const MobileHeaderBlock: React.FunctionComponent<IMobileHeaderBlockProps> = ({ backButtonAction, title }) => {
  const classes = styles();
  return (<div className={classes.header}>
    <div
      className={classNames(classes.headerCol, classes.headerBackIcon)}
      onClick={() => backButtonAction && backButtonAction()}
    >
      <BackArrowIcon className={classes.backIcon}
      />
    </div>
    <div className={classes.title}>{title}</div>
    <div className={classes.headerCol} />
  </div>);
};

export default MobileHeaderBlock;
