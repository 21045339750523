import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const styles = makeStyles((theme) =>
  createStyles({
    '@keyframes load8': {
      '0%': {
        'transform': 'rotate(0deg)'
      },
      '100%': {
        'transform': 'rotate(360deg)'
      }
    },
    icon: {
      animation: '$load8 1.1s infinite linear'
    }

  }));

const LoadingIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  const classes = styles();
  return (
    <svg className={classNames(className, classes.icon)} width="48" height="48" viewBox="0 0 48 48" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="23" stroke="url(#paint0_linear)" strokeWidth="3" />
      <defs>
        <linearGradient id="paint0_linear" x1="10.2" y1="4.2" x2="39.9" y2="43.2" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LoadingIcon;
