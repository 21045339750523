import React from 'react';

const SearchListIcon: React.FunctionComponent<{ className?: string; onClick?: () => void }> = ({ className, onClick }) => {
  return (
    <svg id={'searchListIcon'} className={className} width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.85 7H27.15C27.402 7 27.6436 6.89464 27.8218 6.70711C27.9999 6.51957 28.1 6.26522 28.1 6C28.1 5.73478 27.9999 5.48043 27.8218 5.29289C27.6436 5.10536 27.402 5 27.15 5H13.85C13.598 5 13.3564 5.10536 13.1782 5.29289C13.0001 5.48043 12.9 5.73478 12.9 6C12.9 6.26522 13.0001 6.51957 13.1782 6.70711C13.3564 6.89464 13.598 7 13.85 7ZM29.05 10H11.95C11.698 10 11.4564 10.1054 11.2782 10.2929C11.1001 10.4804 11 10.7348 11 11C11 11.2652 11.1001 11.5196 11.2782 11.7071C11.4564 11.8946 11.698 12 11.95 12H29.05C29.302 12 29.5436 11.8946 29.7218 11.7071C29.8999 11.5196 30 11.2652 30 11C30 10.7348 29.8999 10.4804 29.7218 10.2929C29.5436 10.1054 29.302 10 29.05 10ZM27.15 15H13.85C13.598 15 13.3564 15.1054 13.1782 15.2929C13.0001 15.4804 12.9 15.7348 12.9 16C12.9 16.2652 13.0001 16.5196 13.1782 16.7071C13.3564 16.8946 13.598 17 13.85 17H27.15C27.402 17 27.6436 16.8946 27.8218 16.7071C27.9999 16.5196 28.1 16.2652 28.1 16C28.1 15.7348 27.9999 15.4804 27.8218 15.2929C27.6436 15.1054 27.402 15 27.15 15Z"
        fill="black" />
      <mask id="path-2-outside-1_1296_49" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23" fill="black">
        <rect fill="white" width="23" height="23" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.71 13H14.5L19.49 18L18 19.49L13 14.5V13.71L12.73 13.43C11.59 14.41 10.11 15 8.5 15C4.91 15 2 12.09 2 8.5C2 4.91 4.91 2 8.5 2C12.09 2 15 4.91 15 8.5C15 10.11 14.41 11.59 13.43 12.73L13.71 13ZM4 8.5C4 10.99 6.01 13 8.5 13C10.99 13 13 10.99 13 8.5C13 6.01 10.99 4 8.5 4C6.01 4 4 6.01 4 8.5Z" />
      </mask>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.71 13H14.5L19.49 18L18 19.49L13 14.5V13.71L12.73 13.43C11.59 14.41 10.11 15 8.5 15C4.91 15 2 12.09 2 8.5C2 4.91 4.91 2 8.5 2C12.09 2 15 4.91 15 8.5C15 10.11 14.41 11.59 13.43 12.73L13.71 13ZM4 8.5C4 10.99 6.01 13 8.5 13C10.99 13 13 10.99 13 8.5C13 6.01 10.99 4 8.5 4C6.01 4 4 6.01 4 8.5Z"
            fill="black" />
      <path
        d="M14.5 13L15.9156 11.5872L15.3296 11H14.5V13ZM13.71 13L12.3217 14.4397L12.9028 15H13.71V13ZM19.49 18L20.9042 19.4142L22.317 18.0014L20.9056 16.5872L19.49 18ZM18 19.49L16.5872 20.9056L18.0014 22.317L19.4142 20.9042L18 19.49ZM13 14.5H11V15.3296L11.5872 15.9156L13 14.5ZM13 13.71H15V12.9028L14.4397 12.3217L13 13.71ZM12.73 13.43L14.1697 12.0417L12.8586 10.6821L11.4262 11.9134L12.73 13.43ZM13.43 12.73L11.9134 11.4262L10.6821 12.8586L12.0417 14.1697L13.43 12.73ZM14.5 11H13.71V15H14.5V11ZM20.9056 16.5872L15.9156 11.5872L13.0844 14.4128L18.0744 19.4128L20.9056 16.5872ZM19.4142 20.9042L20.9042 19.4142L18.0758 16.5858L16.5858 18.0758L19.4142 20.9042ZM11.5872 15.9156L16.5872 20.9056L19.4128 18.0744L14.4128 13.0844L11.5872 15.9156ZM11 13.71V14.5H15V13.71H11ZM11.2903 14.8183L11.5603 15.0983L14.4397 12.3217L14.1697 12.0417L11.2903 14.8183ZM8.5 17C10.6071 17 12.5454 16.2261 14.0338 14.9466L11.4262 11.9134C10.6346 12.5939 9.61295 13 8.5 13V17ZM0 8.5C0 13.1946 3.80543 17 8.5 17V13C6.01457 13 4 10.9854 4 8.5H0ZM8.5 0C3.80543 0 0 3.80543 0 8.5H4C4 6.01457 6.01457 4 8.5 4V0ZM17 8.5C17 3.80543 13.1946 0 8.5 0V4C10.9854 4 13 6.01457 13 8.5H17ZM14.9466 14.0338C16.2261 12.5454 17 10.6071 17 8.5H13C13 9.61295 12.5939 10.6346 11.9134 11.4262L14.9466 14.0338ZM15.0983 11.5603L14.8183 11.2903L12.0417 14.1697L12.3217 14.4397L15.0983 11.5603ZM8.5 11C7.11457 11 6 9.88543 6 8.5H2C2 12.0946 4.90543 15 8.5 15V11ZM11 8.5C11 9.88543 9.88543 11 8.5 11V15C12.0946 15 15 12.0946 15 8.5H11ZM8.5 6C9.88543 6 11 7.11457 11 8.5H15C15 4.90543 12.0946 2 8.5 2V6ZM6 8.5C6 7.11457 7.11457 6 8.5 6V2C4.90543 2 2 4.90543 2 8.5H6Z"
        fill="white" mask="url(#path-2-outside-1_1296_49)" />
    </svg>
  );
};

export default SearchListIcon;
