import classNames from 'classnames';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageTypes } from '@constants/pageTypes';
import { UseScrollContext } from '@context/useScroll';
import { IStore } from '@interfaces/store';

import styles from './styles';

const NavigationBarWrap: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const classes = styles();

  const pageType = useSelector<IStore, PageTypes | null>((state) => state.pageData.type);
  const modelId = useSelector<IStore, number | undefined>((state) => state.productDetails.productDetails?.modelId);

  const { fixElement, isKeyboardOpen = false, unpinElement } = useContext(UseScrollContext);
  const [defaultHide, setHide] = useState(false);
  const [defaultShow, setShow] = useState(true);


  useEffect(() => {
    // HIDE
    const hideNavBarInProductDetail = pageType === PageTypes.productDetail;
    if (hideNavBarInProductDetail) {
      setHide(true);
    } else {
      setHide(false);
    }

    // SHOW
    const isPageVisited = history?.state?.__visited;
    const isProductDetailVisited = pageType === PageTypes.productDetail && isPageVisited;
    if (isProductDetailVisited) {
      setShow(true);
    } else {
      setShow(false);
    }

  }, [pageType, modelId]);


  useEffect(() => {
    // CLEAR VALUES
    if (pageType === PageTypes.productDetail) {
      if (modelId) {
        setShow(false);
        setHide(false);
      }
    } else if (defaultHide) {
      setHide(false);
      setShow(false);
    }

  }, [unpinElement]);


  const scrollContextCondition = unpinElement && !fixElement;

  const isHideNavBarInProductDetail = pageType === PageTypes.productDetail && !defaultShow && (scrollContextCondition || defaultHide);
  const isHideNavBar = isKeyboardOpen || isHideNavBarInProductDetail;

  return (
    <div className={classNames(classes.navigation, { [classes.navigationHide]: isHideNavBar })}>
      {children}
    </div>);
};

export default NavigationBarWrap;
