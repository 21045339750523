import { useEffect, useState } from 'react';

export const useDidUpdateEffect = (func: () => void, deps: any[]): void => {
  const [loaded, changeLoaded] = useState(false);
  useEffect(() => {
    if (loaded) {
      func();
    } else {
      changeLoaded(true);
    }
  }, deps);
};
