export const headerHeight = 88;
export const headerHeightSm = 48;
export const headerMenuBar = 40;
export const headerAllHeight = headerHeight + headerMenuBar;
export const filterStickyOffset = headerAllHeight + 12;
export const maxWidth = 1440;

export const appNavigationHeightSm = headerHeightSm;

export const cartPartnersBar = 64;
