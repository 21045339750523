import LogRocket from 'logrocket';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { isCookieEnabled, TYPE_LOGROCKET } from '@components/common/CookieConsent/cookieConsentUtils';
import ErrorView from '@components/common/ErrorBoundary/ErrorView';
import { logRocketEnable, mobileMode } from '@constants/config';
import { IPageData } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { isLogRocketEnabled } from '@utils/siteSettings';

export interface IErrorCatchState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  data: IPageData;
  trackingDisabled: boolean | null | undefined;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps & PropsWithChildren, IErrorCatchState> {

  public static getDerivedStateFromError(error) {
    console.error('getDerivedStateFromError', error);
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error) {
    console.error('componentDidCatch', error);
    if(this.isLogRockedEnable(this.props)){
      LogRocket.captureException(error);
    }
    return { hasError: true };
  }

  public render() {
    return this.state.hasError ? <ErrorView /> : this.props.children;
  }

  private isLogRockedEnable(props: ErrorBoundaryProps) {
    const isDisabledOnApp = !mobileMode || !props.trackingDisabled;
    return (!(props.data && !isLogRocketEnabled(props.data) && !isCookieEnabled(TYPE_LOGROCKET) && !isDisabledOnApp)) && logRocketEnable;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    data: state.pageData.data,
    trackingDisabled: state.nativeSettings.trackingDisabled
  };
};

export default connect(mapStateToProps)(ErrorBoundary);

