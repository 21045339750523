import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    backIcon: {
      '& path': {
        fill: ColorsPalette.primary,
      },
      marginLeft: 0,
      marginTop: 2,
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '22px',
      justifyContent: 'space-between',
      padding: '12px 7px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
      },
    },
    headerBackIcon: {
      '&:hover': {
        backgroundColor: ColorsPalette.neutral2,
      },
      '&:hover $backIcon path': {
        fill: ColorsPalette.hoverActionCTA,
      },
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      height: 40,
      justifyContent: 'center',
      width: 40,
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
      },
    },
    headerCol: {
      alignItems: 'center',
      display: 'flex',
      width: '40px',
    },
    title: {
      alignItems: 'center',
      color: ColorsPalette.primary,
      display: 'flex',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '160%',
      textAlign: 'center',
    }
  }));

export default useStyles;
