import React from 'react';

const ArrowsSubmitIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="24" height="18" viewBox="0 0 24 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M1 17L9 9L1 1" stroke="#EBEBEB" />
      <path opacity="0.5" d="M8 17L16 9L8 1" stroke="#EBEBEB" />
      <path opacity="0.2" d="M15 17L23 9L15 1" stroke="#EBEBEB" />
    </svg>
  );
};

export default ArrowsSubmitIcon;
