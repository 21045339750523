import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    buttonFont: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      justifyContent: 'center',
      lineHeight: '124%',
      textAlign: 'center',
    },
    contained: {
      '&$disabled': {
        background: ColorsPalette.neutral1,
        boxShadow: 'none',
        color: ColorsPalette.secondary,
        cursor: 'not-allowed',
      },
      [theme.breakpoints.up('md')]: {
        '&:hover&:not($disabled)': {
          background: ColorsPalette.hoverActionCTA,
        },
      },
      backgroundColor: ColorsPalette.primary,
    },
    containedPrimary: {
      color: ColorsPalette.neutral1,
    },
    containedSec: {
      '&$disabled': {
        background: ColorsPalette.neutral1,
        boxShadow: 'none',
        color: ColorsPalette.secondary,
      },
      backgroundColor: ColorsPalette.primary,
    },
    containedSecondary: {
      '&$disabled': {
        background: 'transparent',
        border: '2px solid ' + ColorsPalette.neutral1,
        color: ColorsPalette.neutral1,
      },
      '&:hover&$disabled': {
        background: 'transparent',
        border: '2px solid ' + ColorsPalette.neutral1,
        boxShadow: 'none',
        color: ColorsPalette.neutral1,
      },
      '&:hover:not($disabled)': {
        '& svg': {
          '& path': {
            fill: ColorsPalette.hoverActionCTA,
          },
        },
        background: 'transparent',
        border: '2px solid ' + ColorsPalette.hoverActionCTA,
        boxShadow: 'none',
        color: ColorsPalette.hoverActionCTA,
      },

      background: 'transparent',
      border: '2px solid ' + ColorsPalette.primary,
      boxShadow: 'none',
      color: ColorsPalette.primary,
    },
    disabled: {},
    endIcon: {
      display: 'flex',
      flex: '1 1 36px',
      maxWidth: '36px',
    },
    label: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: 'inherit',
    },
    root: {
      '&$disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'all',
      },
      '&$rootBig': {
        height: 64,
      },
      borderRadius: '100px !important',
      height: 56,
      margin: '8px 0',
      textTransform: 'none',
      width: '100%',
    },
    rootBig: {},
    startIcon: {
      '& svg': {
        maxHeight: 19,
        maxWidth: 19,
        width: '100%',
      },
      flex: '0 1 36px',
      justifyContent: 'flex-end',
      minWidth: '16px',
    },
    startIconSecondary: {
      '& svg path': {
        fill: ColorsPalette.primary,
      },
      flex: '0 1 24px',
      justifyContent: 'flex-end',
      minWidth: '16px',
    },
  }),
);
